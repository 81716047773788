import { useStaticQuery, graphql } from "gatsby"
//import { skills } from "../cursos"

const useSkills = () => {
  const resultado = useStaticQuery(
    graphql`
      query {
        allStrapiSkills(sort: { fields: Name }) {
          skills: nodes {
            Name
          }
        }
      }
    `
  )

  return resultado.allStrapiSkills
  //return skills
}

export default useSkills
