import React from "react"

const Experience = ({ experience }) => {
  let [{ experinece_description }] = experience
  let descriptions = experinece_description.split("|")

  return (
    <section className="home__experience">
      <h3>Work Experience</h3>
      <div className="home__experience__description">
        {descriptions.map((p, index) => {
          return <p key={index} dangerouslySetInnerHTML={{ __html: p }} />
        })}
      </div>
    </section>
  )
}

export default Experience
