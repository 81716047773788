import React, { useState } from "react"
import SEO from "../components/seo"
import CardCertificates from "../components/card"
import Categories from "../components/categories"

const Trainings = () => {
  const [categoryNameSelected, setCategoryNameSelected] = useState("")

  const handleFilterCategories = categoryName => {
    setCategoryNameSelected(categoryName)
  }

  return (
    <>
      <SEO title="Home" />
      <section className="categories">
        <Categories handleFilterCategories={handleFilterCategories} />
      </section>
      <section className="certificates">
        <CardCertificates filterCategory={categoryNameSelected} />
      </section>
    </>
  )
}

export default Trainings
