import { useStaticQuery, graphql } from "gatsby"

const useHome = () => {
  const resultado = useStaticQuery(
    graphql`
      query {
        home: allStrapiHomes {
          main: nodes {
            main_description
            main_name
          }
        }
        home: allStrapiHomes {
          about: nodes {
            about_description
            about_extra
          }
        }
        home: allStrapiHomes {
          experience: nodes {
            experinece_description
          }
        }
        home: allStrapiHomes {
          stack: nodes {
            skills_stack {
              back
              cloud
              db
              front
              sls
              test
              version
            }
          }
        }
        home: allStrapiHomes {
          trainings: nodes {
            trainings_description
            trainings_title
          }
        }
        contact: allStrapiContacts {
          nodes {
            title
            url
            icon {
              publicURL
            }
          }
        }
      }
    `
  )

  return resultado
  //return skills
}

export default useHome
