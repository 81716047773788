import React from "react"
import SEO from "../components/seo"
import Home from "../components/Home/Index"

const IndexPage = () => {
  return (
    <>
      <SEO title="Home" />
      <Home />
    </>
  )
}

export default IndexPage
