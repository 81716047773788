import React from "react"
import useIcons from "../hooks/useIcons"

const Logo = ({ name }) => {
  const getIcons = useIcons()
  const GetUrl = name => {
    return getIcons[name.toLowerCase()]
      ? getIcons[name.toLowerCase()].publicURL
      : ""
  }

  return <img alt={name} title={name} src={GetUrl(name)} className="icons" />
}

export const Udemy = () => <Logo name="Udemy" />
export const Pluralsight = () => <Logo name="Pluralsight" />
export const Linkedin = () => <Logo name="Linkedin" />
export const Github = () => <Logo name="Github" />
export const Back = () => <Logo name="Back" />
export const Certificate = () => <Logo name="Certificate" />
export const ReactIcon = () => <Logo name="React" />
export const GraphQL = () => <Logo name="GraphQL" />
export const Gatsby = () => <Logo name="Gatsby" />
export const Email = () => <Logo name="Email" />
export const Terminal = () => <Logo name="Terminal" />
export const LinkedinColor = () => <Logo name="LinkedinColor" />
export const GithubColor = () => <Logo name="GithubColor" />
export const Strapi = () => <Logo name="Strapi" />
