import React from "react"
import { Link } from "gatsby"
import { Certificate } from "../components/icons"
import SEO from "../components/seo"
import { graphql } from "gatsby"
import Image from "gatsby-image"
import urlSlug from "url-slug"

export const query = graphql`
  query($id: Int) {
    allStrapiCourses(filter: { strapiId: { eq: $id } }) {
      nodes {
        id: strapiId
        title
        description
        created_at
        course_url
        completed_date
        platform
        certificate {
          sharp: childImageSharp {
            fluid(maxWidth: 1200) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        paths {
          completed_date
          id
          title
        }
      }
    }
  }
`

const Detail = props => {
  /*
  const initialState = {
    id: 0,
    title: "",
    description: "",
    completed_date: "",
    course_url: "",
    certificate: "",
    skills: [""],
  }
  const [course, setCourse] = useState(initialState)
  useEffect(() => {
    AllCourses.some(el => {
      if (el.id === course.id) {
        setCourse(el)
        return true
      }
    })
  })
  */
  console.log(props)
  const course = props.data.allStrapiCourses.nodes[0]

  const ExistPath = paths => {
    if (!paths.length > 0) {
      return null
    }
    return (
      <div className="detail__text__column__path">
        <div className="detail__text__column__path__header">
          <span>Learning Path</span>
          <span>Completed on</span>
        </div>
        <ul>
          {paths.map((nexItem, index) => {
            return (
              <li key={index} id={nexItem.id}>
                <Certificate />
                <Link to={"../" + urlSlug(nexItem.title)}>
                  <div>{nexItem.title}</div>
                </Link>
                <span>{nexItem.completed_date}</span>
              </li>
            )
          })}
        </ul>
      </div>
    )
  }

  return (
    <>
      <SEO title="Details" />
      <section className="detail__text">
        <div className="detail__text__column">
          <div className="detail__text__column__item">
            <div className="detail__text_column__details">
              <section>
                <div className="detail__text__title">{course.title}</div>
                <div className="detail__text__date">
                  {course.paths.length > 0
                    ? "Learning Path completed on"
                    : "Course completed on"}{" "}
                  {course.completed_date}
                </div>
              </section>
              <span className="detail__text__description">
                {course.description}
              </span>
            </div>
            {ExistPath(course.paths)}
          </div>
          <div className="detail__text__column__item--bottom">
            <div className="detail__text_url">
              <a href={course.course_url} target="_blank" rel="noreferrer">
                View on {course.platform}
              </a>
            </div>
          </div>
        </div>
      </section>
      <section className="detail__img">
        <a href={course.certificate.publicURL} target="_blank" rel="noreferrer">
          <Image fluid={course.certificate.sharp.fluid} />
        </a>
      </section>
    </>
  )
}

export default Detail
