import React from "react"

const Contact = ({ contact }) => (
  <section className="home__contact">
    <div className="home__contact__social">
      {contact.map((item, index) => {
        return (
          <span key={index} id={item.title}>
            <a href={item.url} target="_blank" rel="noreferrer">
              <img
                alt={item.title}
                title={item.title}
                src={item.icon.publicURL}
                className="icons"
              />
            </a>
          </span>
        )
      })}
    </div>
  </section>
)

export default Contact
