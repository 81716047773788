import React from "react"

const HomePage = ({ main }) => {
  let [{ main_name, main_description }] = main

  return (
    <section className="home__title">
      <h1>{main_name}</h1>
      <h2>{main_description}</h2>
    </section>
  )
}
export default HomePage
