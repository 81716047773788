import React from "react"
import PropTypes from "prop-types"
import SideBar from "./SideBar"
import "./layout.css"
import { ReactIcon, GraphQL, Gatsby, Strapi } from "./icons"

const Footer = () => {
  return (
    <footer className="footer">
      <div>
        <span>© {new Date().getFullYear()} AaronMqz Built with</span>
      </div>
      <div className="footer__icons">
        <ReactIcon />
        <span>React</span>
        <Gatsby />
        <span>Gatsby</span>
        <GraphQL />
        <span>GraphQL</span>
        <Strapi />
        <span>Strapi</span>
      </div>
    </footer>
  )
}

const Layout = ({ children, pageContext }) => {
  const layout = pageContext ? pageContext.layout : "undefined"
  console.log("******************", pageContext, layout)
  return (
    <>
      {layout === "trainings" ? (
        <>
          <SideBar />
          <main className="main">{children}</main>
          <Footer />
        </>
      ) : (
        <>
          <main>{children}</main>
        </>
      )}
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
