import { Link } from "gatsby"
import React, { useEffect, useState } from "react"
import useCourses from "../hooks/useCourses"
import Image from "gatsby-image"
import urlSlug from "url-slug"

const Card = ({ certificate, title, completed_date }) => (
  <div className="card">
    <div className="card__img">
      <Image fluid={certificate} />
    </div>
    <div className="card__text">
      <span className="card__title">{title}</span>
      <span className="card__subtitle">{completed_date}</span>
    </div>
  </div>
)

const CardCertificates = ({ filterCategory }) => {
  const cursos = useCourses()
  const [filterCourses, setfilterCourses] = useState(cursos)

  const DefautlFilter = () => {
    setfilterCourses(cursos)
  }

  const HandlerFilter = () => {
    const filter = []
    const filterName = filterCategory.toLowerCase().replace(/\s+/g, "")

    cursos.map((item, index) => {
      item.skills.filter((skill, index) => {
        if (skill.Name.toLowerCase().replace(/\s+/g, "") === filterName) {
          filter.push(item)
        }
      })
    })
    setfilterCourses(filter)
  }

  useEffect(() => {
    if (filterCategory !== "") {
      filterCategory === "All" ? DefautlFilter() : HandlerFilter()
    }
  }, [filterCategory])

  return (
    <>
      {filterCourses.map((item, key) => {
        return (
          <Link key={key} to={urlSlug(item.title)}>
            <Card
              id={item.id}
              certificate={item.certificate.sharp.fluid}
              title={item.title}
              completed_date={item.completed_date}
            />
          </Link>
        )
      })}
    </>
  )
}

export default CardCertificates
