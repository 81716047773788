import React, { useState } from "react"
import { Terminal } from "../icons"

const About = ({ about }) => {
  const [{ about_description, about_extra }] = about
  let descriptions = about_description.split("|")
  const [showIframe, setShowIframe] = useState(false)

  console.log(about_extra)

  const handleCLick = e => {
    setShowIframe(!showIframe)
  }

  const Iframe = () => {
    return (
      <iframe
        height="600px"
        width="100%"
        src="https://repl.it/@AaronMqz/Reverse-Integer?lite=true"
        scrolling="yes"
        frameborder="no"
        allowtransparency="true"
        allowfullscreen="true"
        sandbox="allow-forms allow-pointer-lock allow-popups allow-same-origin allow-scripts allow-modals"
      ></iframe>
    )
  }
  console.log(showIframe)
  return (
    <section className="home__about">
      <h3>About me</h3>
      <div className="home__about__description">
        {descriptions.map((p, index) => {
          return <p key={index} dangerouslySetInnerHTML={{ __html: p }} />
        })}
      </div>
      <div className="home__about__other">
        {showIframe ? (
          Iframe()
        ) : (
          <>
            <div onClick={handleCLick} className="home__about_other--terminal">
              <Terminal />
              <span className="home__about__other--typewriter">
                Click to open a problem
              </span>
            </div>
          </>
        )}
      </div>
    </section>
  )
}

export default About
