import React from "react"
import { Link } from "gatsby"

const Trainings = ({ trainings }) => {
  let [{ trainings_description, trainings_title }] = trainings
  let descriptions = trainings_description.split("|")
  return (
    <section className="home__trainings">
      <h3>{trainings_title}</h3>
      <div className="home__trainings__description">
        {descriptions.map((p, index) => {
          return <p key={index} dangerouslySetInnerHTML={{ __html: p }} />
        })}
      </div>
      <Link to="/trainings">
        <div className="home__trainings__button">View Trainings</div>
      </Link>
    </section>
  )
}

export default Trainings
