import { useStaticQuery, graphql } from "gatsby"

const useIcons = () => {
  const resultado = useStaticQuery(graphql`
    query {
      pluralsight: file(relativePath: { eq: "pluralsight.png" }) {
        publicURL
      }
      udemy: file(relativePath: { eq: "udemy-icon.svg" }) {
        publicURL
      }
      linkedin: file(relativePath: { eq: "linkedin.png" }) {
        publicURL
      }
      github: file(relativePath: { eq: "github.png" }) {
        publicURL
      }
      certificate: file(relativePath: { eq: "certificate.svg" }) {
        publicURL
      }
      react: file(relativePath: { eq: "react.svg" }) {
        publicURL
      }
      graphql: file(relativePath: { eq: "graphql.svg" }) {
        publicURL
      }
      gatsby: file(relativePath: { eq: "gatsby.svg" }) {
        publicURL
      }
      email: file(relativePath: { eq: "email.png" }) {
        publicURL
      }
      ust: file(relativePath: { eq: "ust.png" }) {
        publicURL
      }
      hdi: file(relativePath: { eq: "hdi.png" }) {
        publicURL
      }
      bb: file(relativePath: { eq: "bb.jpg" }) {
        publicURL
      }
      chc: file(relativePath: { eq: "chc.png" }) {
        publicURL
      }
      terminal: file(relativePath: { eq: "terminal.svg" }) {
        publicURL
      }
      linkedincolor: file(relativePath: { eq: "linkedin-color.png" }) {
        publicURL
      }
      githubcolor: file(relativePath: { eq: "github-color.png" }) {
        publicURL
      }
      strapi: file(relativePath: { eq: "strapi.svg" }) {
        publicURL
      }
    }
  `)

  return resultado
}

export default useIcons
