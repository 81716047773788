import React from "react"
import { ReactIcon, GraphQL, Gatsby, Strapi } from "../icons"

const Footer = () => {
  return (
    <footer className="home__footer">
      <div>
        <span>© {new Date().getFullYear()} AaronMqz Built with</span>
      </div>
      <div className="home__footer__icons">
        <ReactIcon />
        <span>React</span>
        <Gatsby />
        <span>Gatsby</span>
        <GraphQL />
        <span>GraphQL</span>
        <Strapi />
        <span>Strapi</span>
      </div>
    </footer>
  )
}

export default Footer
