import React from "react"

const Skills = ({ skills: [{ skills_stack }] }) => (
  <section className="home__skills">
    <h3>Technology Stack</h3>
    <p>Some of the technology stack I've been working with recently:</p>
    <div className="home__skills__stack">
      <div className="home__skills__front">
        {skills_stack.front.map((item, index) => {
          return <div key={index}>{item}</div>
        })}
      </div>
      <div className="home__skills__back">
        {skills_stack.back.map((item, index) => {
          return <div key={index}>{item}</div>
        })}
      </div>
      <div className="home__skills__services">
        <div className="home__skills__services--db">
          {skills_stack.db.map((item, index) => {
            return <div key={index}>{item}</div>
          })}
        </div>
        <div className="home__skills__services--version">
          {skills_stack.version.map((item, index) => {
            return <div key={index}>{item}</div>
          })}
        </div>
        <div className="home__skills__services--test">
          {skills_stack.test.map((item, index) => {
            return <div key={index}>{item}</div>
          })}
        </div>
      </div>
      <div className="home__skills__others">
        {skills_stack.sls.map((item, index) => {
          return <div key={index}>{item}</div>
        })}
      </div>
      <div className="home__skills__cloud">
        {skills_stack.cloud.map((item, index) => {
          return <div key={index}>{item}</div>
        })}
      </div>
    </div>
  </section>
)

export default Skills
