import React from "react"
import { Link } from "gatsby"
import {
  Udemy,
  Pluralsight,
  LinkedinColor,
  GithubColor,
} from "../components/icons"

const SideBar = () => (
  <aside className="sidebar">
    <div className="sidebar__container">
      <div className="sidebar__title">
        <Link to="/">
          <h1>AaronMqz</h1>
        </Link>
        <h2>Software Engineer</h2>
      </div>
      <div className="sidebar__icons">
        <span id="linkedin">
          <a
            href="https://www.linkedin.com/in/aaron-mqz/"
            target="_blank"
            rel="noreferrer"
          >
            <LinkedinColor />
          </a>
        </span>
        <span id="pluralsight">
          <a
            href="https://app.pluralsight.com/profile/AaronMqz"
            target="_blank"
            rel="noreferrer"
          >
            <Pluralsight />
          </a>
        </span>
        <span id="udemy">
          <a
            href="https://www.udemy.com/user/osvaldoaarnmrquezespinoza/"
            target="_blank"
            rel="noreferrer"
          >
            <Udemy />
          </a>
        </span>
        <span id="github">
          <a
            href="https://github.com/AaronMqz"
            target="_blank"
            rel="noreferrer"
          >
            <GithubColor />
          </a>
        </span>
      </div>
      <div className="sidebar__info">
        * Udemy user is required to view my profile.
      </div>
    </div>
  </aside>
)

export default SideBar
