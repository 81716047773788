import React from "react"
import "./layout-home.css"
import Experience from "./Experience"
import About from "./About"
import HomePage from "./HomePage"
import Trainings from "./Trainings"
import Contact from "./Contact"
import Footer from "./Footer"
import Skills from "./Skills"
import useHome from "../../hooks/useHome"
/*
  experience: [
    {
      id: 1,
      company: "UST Global",
      description: [
        {
          rol: "Software Engineer 1",
          date: "May 2017 - Present",
          activities: ["dasdsadsadas d das dasdad adasda"],
        },
      ],
    },
    {
      id: 2,
      company: "HDI Seguros",
      description: [
        {
          rol: "Software Engineer 2",
          date: "May 2017 - Present",
          activities: ["dasdsadsadas d das dasdad adasda"],
        },
        {
          rol: "Software Engineer 2.1",
          date: "May 2017 - Present",
          activities: ["dasdsadsadas d das dasdad adasda"],
        },
      ],
    },
    {
      id: 3,
      company: "Banamex",
      description: [
        {
          rol: "Software Engineer 3",
          date: "May 2017 - Present",
          activities: ["dasdsadsadas d das dasdad adasda"],
        },
      ],
    },
    {
      id: 4,
      company: "Banbajio",
      description: [
        {
          rol: "Software Engineer 4",
          date: "May 2017 - Present",
          activities: ["dasdsadsadas d das dasdad adasda"],
        },
      ],
    },
  ],

*/
/*
const aaron = {
  main: {
    title: "Hi, my name is",
    name: "Aaron Marquez",
    description: "Software Engineer",
  },
  about: {
    description: `Hi there! I’m a Software Engineer from Mexico with 10 years of experience in the IT 
    industry for enterprise software. Currently I'm working for <a href="https://www.ust-global.com/" target="_blank">UST Global</a> based on Leon, Gto Mexico 
    and working remotely on projects for clients in the United States such as <a href="https://www.changehealthcare.com/" target="_blank">ChangeHealthcare</a>.|
    My most recent hobby is resolving algorithm and data structures problems.`,
    extra: "",
  },
  experience: {
    description: `In my professional career I worked in banking, insurance and health companies, developing all kind of software 
    such as desktop, console, mobile, web, database management, cloud services…| 
    Over the past then years, I was worked in all the <strong>Software Development Process</strong> since Analysis, 
    Defining Requirements, System Design, Development, Testing and Deployment.|
    If you would like to know more about me take a look to my <a href="https://www.linkedin.com/in/aaron-mqz/">LinkedIn</a> profile`,
  },
  trainings: {
    title: "One more thing...",
    description: `Do you know what is my weakness? It’s try to be a Human Robot learning all kind of 
    technologies, everyday I'm reading, watching tutorials, taking courses regarding to different 
    programing languages, cloud services, AI, ML, bots development, Alexa skills development, 
    web scraping, JavaScript libraries/frameworks, and much more... 
    |If you don't believe me, take a look...`,
  },
  contact: [
    {
      title: "Github",
      icon: "",
    },
    {
      title: "LinkedIn",
      icon: "",
    },
    {
      title: "Email",
      icon: "",
    },
  ],
}
*/

const Home = () => {
  const { home, contact } = useHome()
  return (
    <div className="home__container">
      <HomePage main={home.main} />
      <About about={home.about} />
      <Experience experience={home.experience} />
      <Skills skills={home.stack} />
      <Trainings trainings={home.trainings} />
      <Contact contact={contact.nodes} />
      <Footer />
    </div>
  )
}

export default Home
