import React, { useState, useRef, useEffect } from "react"
import useSkills from "../hooks/useSkills"

const style = {
  categoryButton: "category__button",
  categoryButtonActive: "category__button--active",
}

const Categories = ({ handleFilterCategories }) => {
  const [buttonActive, setButtonActive] = useState(0)
  const buttonRef = useRef([])
  const { skills } = useSkills()
  const defaultFilterName = "All"

  const handelButtonActiveStyle = index => {
    const button = buttonRef.current[index]
    // remove active class
    if (buttonActive >= 0) {
      buttonRef.current[buttonActive].className = style.categoryButton
    }

    // set new active class
    button.className += " " + style.categoryButtonActive
    setButtonActive(index)
  }

  const handleButton = (index, categoryName) => {
    handelButtonActiveStyle(index)
    handleFilterCategories(categoryName)
  }

  useEffect(() => {
    const handleDefaultFilter = name => {
      const index = skills.findIndex(sk => sk.Name === defaultFilterName)
      if (index >= 0) {
        handleButton(index, name)
      }
    }
    handleDefaultFilter(defaultFilterName)
  }, [skills])

  const formatCategory = category => {
    if (category === "ML") {
      return "Machine Learning"
    }
    return category
  }

  return (
    <div className="categories__buttons">
      {skills.map((skill, index) => {
        return (
          <button
            key={index}
            ref={el => (buttonRef.current[index] = el)}
            className={"category__button"}
            onClick={() => handleButton(index, skill.Name)}
          >
            {formatCategory(skill.Name)}
          </button>
        )
      })}
    </div>
  )
}
export default Categories
