import { useStaticQuery, graphql } from "gatsby"
//import { skills } from "../cursos"

/*
const useCourses = () => {
  const resultado = useStaticQuery(
    graphql`
      query {
        allStrapiCourses {
          courses: nodes {
            strapiId
            description
            created_at
            completed_date
            course_url
            title
            skills {
              Name
            }
            certificate {
              sharp: childImageSharp {
                fluid(maxWidth: 600, maxHeight: 400) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    `
  )
*/

const useCourses = () => {
  const resultado = useStaticQuery(
    graphql`
      query {
        allStrapiCourses(sort: { order: DESC, fields: completed_date }) {
          courses: nodes {
            strapiId
            description
            created_at
            completed_date
            course_url
            title
            skills {
              Name
            }
            certificate {
              sharp: childImageSharp {
                fluid(maxWidth: 600, maxHeight: 400) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    `
  )

  return resultado.allStrapiCourses.courses
  //return skills
}

export default useCourses
